import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const BlogPostTemplate = class extends React.Component {
  render() {
    const {
      content,
      contentComponent,
      tags,
      title,
      helmet,
      date
    } = this.props;
    const PostContent = contentComponent || Content

    return (
      <React.Fragment>
        {helmet || ''}
        <h1>{title}</h1>
        <small>{date}</small>
        <PostContent content={content} className="post-content" />
        <div className="addthis-wrapper">
          <div className="addthis_inline_share_toolbox_cq7h"></div>
        </div>
        <div className="addthis-wrapper">
          <div className="addthis_relatedposts_inline"></div>
        </div>
        {tags && tags.length ? (
          <div className="tags are-large">
            {tags.map(tag => (
              <span key={tag + `tag`} className="tag is-small is-light">
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </span>
            ))}
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}
BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
